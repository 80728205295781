export interface CustomerAdditionalChargesApiResponse {
  id: string;
  count: string | number;
  baseUnit: number | string;
  chargeFor: string;
  costType: string;
  costValue: string;
  isActive: boolean;
  unitPiece: number | string;
  unitPiecePrice: number | string | null;
  customer: {
    id: string;
    name: string;
  };
  additionalCharge: {
    id: string;
    name: string;
  };
}
export const CustomerAdditionalChargesTableConfig = {
  columns: [
    { key: "count", header: "S.", isSortable: false, className: "count" },
    {
      key: "rateChargeCode",
      header: "Rate Charge Code",
      isSortable: true,
      className: "dark",
    },
    {
      key: "chargeFor",
      header: "Charge For",
      isSortable: true,
      className: "dark",
    },
    {
      key: "costType",
      header: "Cost Type",
      isSortable: true,
      upperCase: true,
      className: "dark",
    },
    {
      key: "costValue",
      header: "Cost Value",
      isSortable: true,
      upperCase: true,
      className: "dark",
    },
    {
      key: "base",
      header: "Base",
      isSortable: true,
      upperCase: true,
      className: "dark",
    },
    {
      key: "unit",
      header: "Unit",
      isSortable: true,
      upperCase: true,
      className: "dark",
    },
    {
      key: "unitPrice",
      header: "Unit Price",
      isSortable: true,
      upperCase: true,
      className: "dark",
    },
    {
      key: "active",
      header: "Active",
      isSortable: false,
      isActive: true,
      handleActive: true,
      className: "dark",
    },
  ],
  edit: {
    apiUrl: "/customers/additional-charges/edit/",
    urlID: "id",
    stateData: false,
  },
  delete: {
    apiUrl: "/customer/charges/",
    urlID: "id",
    successMessage: "Customer Additional Charges deleted successfully!",
    heading: "Delete Customer Additional Charges!",
    para: "delete",
    rowName: "customerName",
    secondRowName: "",
  },
  active: {
    apiUrl: "/customer/charges/",
    urlID: "id",
    successMessage: "Customer Additional Charges",
    heading: "Customer Additional Charges!",
    para: "",
    rowName: "customerName",
    secondRowName: "",
    onboardedMessage: "Customer Additional Charges",
    notCheckedOnboarded: true,
  },
};
interface ColumnTypes {
  key: string;
  title: string;
}
export const AddEditCustomerAdditionalChargeColumns: ColumnTypes[] = [
  { key: "name", title: "Code" },
  { key: "description", title: "Description" },
  { key: "chargeFor", title: "Charge For" },
];
export interface CustomerAdditionalChargeDropdownType {
  id?: string;
  name: string | null;
}
export interface CustomerAdditionalChargeDetailsData {
  id: string;
  updatedAt: string;
  createdAt: string;
  description: string;
  chargeType: string;
  chargeFor: string;
  useMarkup: boolean;
  useFSCPer: boolean;
  chargeToAgent: boolean;
  recalculate: boolean;
  isActive: boolean;
  baseUnit: number; // Updated from your API response
  unitPiece: number; // Updated from your API response
  unitPiecePrice: string | null; // Updated to match nullable data type
  costType: string;
  costValue: string;
  customer: {
    id: string;
    updatedAt: string;
    createdAt: string;
    name: string;
    customerNumber: string;
    email: string;
    url: string;
    logo: string;
    contactPerson: string;
    phoneNumber: string;
    countryCallingCode: string;
    phoneExtention: string;
    faxAccessCode: string;
    masterCustomerGUID: string;
    useWeightZone: boolean;
    isActive: boolean;
    isDeleted: boolean;
    orderMails: boolean;
    isSignedUp: boolean;
    isDirectCustomer: boolean;
    customerLevel: string;
  };
  agentHub: {
    id: string;
    updatedAt: string;
    createdAt: string;
    name: string;
    phoneNumber: string;
    countryCallingCode: string;
    phoneExt: string;
    activeDate: string | null;
    deactiveDate: string | null;
    isActive: boolean;
    operationEmail: string[];
    orderMails: boolean;
    disabledServices: string | null;
  };
  additionalCharge: {
    id: string;
    updatedAt: string;
    createdAt: string;
    name: string;
    description: string;
    chargeType: string;
    chargeFor: string;
    useMarkup: boolean;
    useFSCPer: boolean;
    chargeToAgent: boolean;
    recalculate: boolean;
    isActive: boolean;
    fixedCharge: boolean;
    costType: string;
    costValue: string;
    priceOnMiles: boolean;
    chargeOnWeight: boolean;
    mandatory: boolean;
    chargeOnMile: boolean;
    chargeOnWaitTime: boolean;
    chargeOnPiece: boolean;
    chargeOnStorage: boolean;
    chargeOnStairs: boolean;
    chargeExtraMen: boolean;
    chargeOnPocket: boolean;
    chargeOnPlanet: boolean;
    chargeOnHandlingUnit: boolean;
  };
}

// Enum for names
export enum AdditionalChargeNames {
  AdditionalPiece = "Additional Piece",
  AttemptFee = "Attempt Fee",
  CrossDock = "Cross Dock",
  ExtraMan = "Extra Man",
  ForwardAirTransfer = "ForwardAir Transfer",
  FuelSurcharge = "Fuel Surcharge",
  HandlingUnitCharge = "Handling Unit Charge",
  LinehaulFee = "Linehaul Fee",
  OrderSwapExchange = "Order Swap & Exchange",
  OverMileage = "Over Mileage",
  OverWeight = "Over Weight",
  PackageProductForLtlPickup = "PACKAGE PRODUCT FOR LTL PICKUP",
  RemoveDisposal = "Remove & Disposal",
  RemoveDisposeDestroy = "REMOVE AND DISPOSE/DESTROY",
  SpotQuote = "Spot Quote",
  Stairs = "Stairs",
  Storage = "Storage",
  TechnologyFee = "Technology Fee",
  WaitTime = "Wait Time",
}

// Updated Object mapping
export const ChargeLabels: Record<
  AdditionalChargeNames,
  { first: string; second: string; third: string }
> = {
  [AdditionalChargeNames.AdditionalPiece]: {
    first: "Base Piece",
    second: "Unit Piece",
    third: "Unit Piece Price",
  },
  [AdditionalChargeNames.AttemptFee]: {
    first: "Cost Type",
    second: "Cost Value",
    third: "",
  },
  [AdditionalChargeNames.CrossDock]: {
    first: "Cost Type",
    second: "Cost Value",
    third: "",
  },
  [AdditionalChargeNames.ExtraMan]: {
    first: "Base Extra Man",
    second: "Base Extra Man Unit",
    third: "Base Extra Man Price",
  },
  [AdditionalChargeNames.ForwardAirTransfer]: {
    first: "Cost Type",
    second: "Cost Value",
    third: "",
  },
  [AdditionalChargeNames.FuelSurcharge]: {
    first: "Cost Type",
    second: "Cost Value",
    third: "",
  },
  [AdditionalChargeNames.HandlingUnitCharge]: {
    first: "Base Handling Unit",
    second: "Base Ext Handling Unit",
    third: "Base Ext Handling Unit Price",
  },
  [AdditionalChargeNames.LinehaulFee]: {
    first: "Cost Type",
    second: "Cost Value",
    third: "",
  },
  [AdditionalChargeNames.OrderSwapExchange]: {
    first: "Cost Type",
    second: "Cost Value",
    third: "",
  },
  [AdditionalChargeNames.OverMileage]: {
    first: "Base Mile",
    second: "Unit Mile",
    third: "Unit Mile Price",
  },
  [AdditionalChargeNames.OverWeight]: {
    first: "Base Weight",
    second: "Unit Weight",
    third: "Unit Weight Price",
  },
  [AdditionalChargeNames.PackageProductForLtlPickup]: {
    first: "Cost Type",
    second: "Cost Value",
    third: "",
  },
  [AdditionalChargeNames.RemoveDisposal]: {
    first: "Cost Type",
    second: "Cost Value",
    third: "",
  },
  [AdditionalChargeNames.RemoveDisposeDestroy]: {
    first: "Cost Type",
    second: "Cost Value",
    third: "",
  },
  [AdditionalChargeNames.SpotQuote]: { first: "", second: "", third: "" },
  [AdditionalChargeNames.Stairs]: {
    first: "Base No. of Flight (Stairs)",
    second: "Unit No. of Flight (Stairs)",
    third: "Unit No. of Flight (Stairs) Price",
  },
  [AdditionalChargeNames.Storage]: {
    first: "Cost Type",
    second: "Cost Value",
    third: "",
  },
  [AdditionalChargeNames.TechnologyFee]: {
    first: "Cost Type",
    second: "Cost Value",
    third: "",
  },
  [AdditionalChargeNames.WaitTime]: {
    first: "Base Wait Time",
    second: "Unit Wait ",
    third: "Unit Wait Time",
  },
};
