import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { NavbarState } from "./navbarTypes";
import {
  EMAIL_KEY,
  ROLES_KEY,
  USER_NAME,
  USER_PROFILE,
  USER_ID,
  COMPANY_LOGO
} from "../../../utils/authUtils";

const initialState: NavbarState = {
  title: "",
  username: localStorage.getItem(USER_NAME) ?? "",
  userProfile: localStorage.getItem(USER_PROFILE) ?? "",
  userRole: localStorage.getItem(ROLES_KEY) ?? null,
  userEmail: localStorage.getItem(EMAIL_KEY) ?? null,
  companyLogo: localStorage.getItem(COMPANY_LOGO) ?? ""
};

const navbarSlice = createSlice({
  name: "navbar",
  initialState,
  reducers: {
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },

    setUserInfo: (
      state,
      action: PayloadAction<{
        username: string;
        userId: string;
        userEmail: string;
        userRole: string;
        userProfile: string;
        companyLogo: string;
      }>
    ) => {
      const { username, userId, userEmail, userRole, userProfile, companyLogo } =
        action.payload;
      state.username = username;
      state.userEmail = userEmail;
      state.userRole = userRole;
      state.userProfile = userProfile;
      state.companyLogo = companyLogo;
      localStorage.setItem(USER_NAME, username);
      localStorage.setItem(USER_ID, userId);
      localStorage.setItem(USER_PROFILE, userProfile);
      localStorage.setItem(COMPANY_LOGO, companyLogo);
    },
  },
});

export const { setTitle, setUserInfo } = navbarSlice.actions;

export default navbarSlice.reducer;
