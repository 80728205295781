import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  hideModalState,
  setFormChanged,
  showModalState,
  setFireCode,
  setNextTab
} from "../redux/reducer/prompt/promptSlice";
import { RootState } from "../redux/store";
import { useNavigate } from "react-router-dom";

/**
 * Custom hook to manage form changes and prompt the user before navigation.
 * It tracks form changes and handles user prompts when trying to navigate away with unsaved changes.
 */
const useFormChangeHandler = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isFormChanged, showModal, getPath, fireCode, nextTab } = useSelector(
    (state: RootState) => state.prompt
  );

  /**
   * Event listener for browser window unload event.
   * Prevents the user from accidentally leaving the page with unsaved form changes.
   */
  window.onbeforeunload = (e: BeforeUnloadEvent) => {
    if (isFormChanged) {
      e.preventDefault();
      return ""; // For some browsers
    }
  };

  /**
   * useEffect hook to handle browser history changes when the form has unsaved changes.
   * Prevents the user from navigating back without confirming the action.
   */
  useEffect(() => {
    if (isFormChanged) {
      const handlePopState = (event: PopStateEvent) => {
        event.preventDefault();
        dispatch(showModalState());
      };
      window.addEventListener("popstate", handlePopState);
      window.history.pushState(null, "");

      return () => {
        window.removeEventListener("popstate", handlePopState);
      };
    }
  }, [isFormChanged, dispatch]);

  /**
   * Function to be called on form input change.
   * Sets the form as changed to track unsaved modifications.
   */
  const checkInputChange = () => {
    dispatch(setFormChanged(true));
  };

  /**
   * Handles navigation when there are unsaved form changes.
   * Shows a modal to the user asking for confirmation before navigating away.
   */
  const handleNavigation = () => {
    dispatch(showModalState());
  };

  const handleNextTab = (tab: string) => {
    dispatch(setNextTab(tab));
  };

  /**
   * Confirms the user's intent to navigate away from the current page.
   * Resets the form changed state and navigates to the new path.
   */
  const handleConfirmNavigation = () => {
    dispatch(setFormChanged(false));
    dispatch(hideModalState());
    dispatch(setFireCode(true));
    // add the where user navigate
    window.history.pushState({}, "", getPath);
    navigate(getPath);
    // get history back
    window.history.back();
  };

  /**
   * Handles the update navigation scenario where the form changes are confirmed and saved.
   * Resets the form changed state and hides the modal.
   */
  const handleUpdateNavigation = () => {
    dispatch(setFormChanged(false));
    dispatch(hideModalState());
    dispatch(setFireCode(false));
    dispatch(setNextTab(""));
  };

  /**
   * Cancels the navigation and closes the modal, keeping the user on the current page.
   */
  const handleCancelNavigation = () => {
    dispatch(hideModalState());
  };

  const setFireCodeState = (state: boolean) => {
    dispatch(setFireCode(state));
  };  

  return {
    checkInputChange,
    handleNavigation,
    handleConfirmNavigation,
    handleCancelNavigation,
    showModal,
    isFormChanged,
    handleUpdateNavigation,
    fireCode,
    handleNextTab,
    nextTab,
    setFireCodeState
  };
};

export default useFormChangeHandler;
